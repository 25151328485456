import * as client_hooks from "/src/hooks.client.js";


export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js"),
	() => import("/.svelte-kit/generated/client/nodes/4.js"),
	() => import("/.svelte-kit/generated/client/nodes/5.js"),
	() => import("/.svelte-kit/generated/client/nodes/6.js"),
	() => import("/.svelte-kit/generated/client/nodes/7.js"),
	() => import("/.svelte-kit/generated/client/nodes/8.js"),
	() => import("/.svelte-kit/generated/client/nodes/9.js"),
	() => import("/.svelte-kit/generated/client/nodes/10.js"),
	() => import("/.svelte-kit/generated/client/nodes/11.js"),
	() => import("/.svelte-kit/generated/client/nodes/12.js"),
	() => import("/.svelte-kit/generated/client/nodes/13.js")
];

export const server_loads = [];

export const dictionary = {
		"/": [~3],
		"/coming_soon": [~4],
		"/extra_pages/cevex_dental_expo_2023_stats": [5],
		"/extra_pages/vb_summit_2024_stats": [6],
		"/list_websites": [~7],
		"/logger/error": [~8],
		"/logger/log": [~9],
		"/logger/realtime": [~10],
		"/temp_routes/Regalo_2024_Check_In": [11],
		"/[...optional_website_id]/page/[page_unique_name]": [~12],
		"/[website_partial_id]": [~13,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from "/.svelte-kit/generated/root.svelte";